import React from "react"
import Layout from "../components/layout"

// import "../fontawesome/css/all.min.css"
import "./index.css"

const TrophyList = () => (
  <div className="App">
    <Layout>

            <div className="container">
      <div>
        <div className="row">
          <div className="col-md-12">
            <h1>2024 - 20th Annual Trophy List and Classes</h1>
            <p></p>
            <h2>Trophy Information</h2>

      

<ul>
<li className="mb-0">33 CLASSES</li>
<li className="mb-0">99 BEST IN CLASS TROPHIES</li>
<li className="mb-0">EXTRA TROPHIES FOR LARGER CLASSES IF NEEDED</li>
<li className="mb-0">9 SPECIALTY TROPHIES AS LIST IN TROPHY LIST</li>
</ul>
<p>One trophy per car with no exceptions.</p>
<h5>ANY VEHICLE 2019
OR NEWER WILL BE SHOW ONLY</h5>


<h5>ANY CORVETTE 2010 OR NEWER WILL BE SHOW ONLY</h5>
          </div>
        </div>
        <div className="row">
    
      <div className="col-md-8 ">   
         <div className="row">
        <h2>Class Listing</h2>
        
        
          <div className="col-md-6 ">
 
1. OVERALL GRAND CHAMPION <br/>
2. Best of Show Stock<br/>
3. Best of Show Modified<br/>
4. Best Engine<br/>
5. Best Paint<br/>
6. Best Interior<br/>
7. Participants Choice<br/>
8. Village President’s Choice <br/>
9. Missionary Choice<br/>
A - Stock 1949 and older<br/>
B - Stock 1950-59<br/>
C - Stock 1960-63<br/>
D - Stock 1964-66<br/>
E - Stock 1967-68<br/>
F - Stock 1969-70<br/>
G - Stock 1971-79<br/>
H - Stock 1980-94<br/>
I - Stock 1995-2007 <br/>
J - Stock 2008-2018 <br/>
K - Modified 1949-59 <br/>
L - Modified 1960-66 <br/>
M - Modified 1967-70 <br/>
N - Modified 1971-79 <br/>


          </div>
          <div className="col-md-6">
          O - Modified 1980-94<br/>
P - Modified 1995-2007<br/>
Q - Modified 2008- 2018<br/>
R - Street rods 1900-1934<br/>
S - Street rods 1935-1948<br/>
T - Stock trucks 1965 and older<br/>
U - Stock trucks 1966- 99<br/>
V - Stock trucks 2000-2018<br/>
W - Modified trucks 1965 and older <br/>
X - Modified trucks 1966-2018<br/>
Y - All Corvette Stock 1953-82<br/>
Z - All Corvette Stock 1984-2010 <br/>
AA - All Corvette Modified 53-2010 <br/>
BB - Vipers, Sports cars, AC Cobras, Factory 5, Exotics and kit cars<br/>
CC - Survivor 1985- and older <br/>
DD - Rat rods all years<br/>
EE - Stock Import 1989 and older <br/>
FF - Stock Import 1990- 2018 <br/>
GG - Modified Imports 1990-2018 <br/>
HH - Participant voting for Best Judge or staff car<br/>
II - Vintage, Historic/Nostalgic race cars <br/>
 </div>
 
 </div>
 
   <div className="row">
   <div className="col-md-12">
   <br/><br/>
   <h5>Class II- Vintage, Historic/Nostalgic Race cars <br/> CROWD JUDGING ON THIS CLASS ONLY. </h5>
 Participants and spectators pick your favorite race car! Ballots available at the canopy on the football field next to the race cars.
 
 </div>
 </div>
 </div>
            
            
          <div className="col-md-4">
            <h2>Registration Fees&nbsp;&nbsp; </h2>
            <p />
            
                 <strong>Day of Show Registration</strong><br/>
           
             $10/Judged (1st 300 receive dash plaque)<br/>
           $5/Show Only<br/>
           $15/cars with “for sale” signs<br/>
            
              <strong>Spectators Admission</strong><br/>
          
             Free-will donation<br/><br/>
              <hr className="" />
            <h5>Additional information </h5>
            608-697-6288
<br/>
 <hr className="" />
            <h2> Rules </h2>
             <ol>
               <li>
                 Winner of Trophies must be present to receive their trophy immediately, or
                 have a designated representative to collect it at the time of
                 distribution, or forfeit the trophy.
               </li>
               <li>
                 In order to get everyone in and parked quickly, there will be no reserved
                 parking. If your club wants to park together, you must arrive together.{" "}
               </li>
               <li>All vehicles to be judged must be registered by <strong>12:00 noon</strong>. </li>
               <li>
               Guidelines for Survivors class: Cars exterior, interior and engine must be original. Normal wear items such as hoses, belts, exhaust tire can be replaced.
               {" "}
               </li>
             </ol>
          </div>
        </div>
      </div>

      </div>
    </Layout>
  </div>
)
export default TrophyList
